import { LocationOn, Event, Info } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import InfoBox from '../common/InfoBox'
import Paragraph from '../common/Paragraph'
import { PlanningMailToLink } from '../common/MailToLink'
import LabelIcon from '../common/LabelIcon'

type CommunityGroupInfo = {
  name: string
  topicDescription: string
  date: string
  location: string
}

const listOfCommuntiyGroupInfo: CommunityGroupInfo[] = [
  {
    name: 'Bryan & Bethany',
    topicDescription: 'Reading Pilgrims Progress by John Bunyan',
    date: 'Every Friday (except the last Friday of each month) at 7:00PM',
    location: 'At church',
  },
  {
    name: 'Caleb & Amanda',
    topicDescription: 'Hanging out',
    date: 'Every other Friday at 7:30PM',
    location: 'Tustin',
  },
  {
    name: 'DJ & Annie',
    topicDescription: 'Dinner + Hanging out',
    date: 'First and third Friday of every month at 6:00PM',
    location: 'Yorba Linda',
  },
  {
    name: 'Nick & Ashley',
    topicDescription: 'Hanging out',
    date: 'First and third Friday of every month at 7:30PM',
    location: 'Irvine (Woodbridge)',
  },
  {
    name: 'Ryan & Vione',
    topicDescription: 'Hanging out',
    date: 'Every other Friday at 7:30PM',
    location: 'Tustin',
  },
  {
    name: 'Roger & Jasmine',
    topicDescription: 'Hanging out',
    date: 'First and third Friday of every month at 7:30PM',
    location: 'Tustin',
  },
  {
    name: 'Enrique & Christina',
    topicDescription: 'Potluck dinner, praise, fellowship',
    date: 'Every other Friday at 7:30PM',
    location: 'Irvine (Westpark)',
  },
  {
    name: 'Andrew & Jasmine',
    topicDescription: 'Potluck dinner, fellowship (note: they have cats)',
    date: 'Every 1st and 3rd Friday at 6:30PM',
    location: 'Irvine (Great Park)',
  },
]

const sectionSpacing = '30px'

const StyledInfoBox = styled(InfoBox)`
  > div:not(:last-child) {
    border-bottom: 1px #d3d3d3 solid;
  }
`

const InfoLine = styled(Stack)`
  margin: 0 0 10px 10px;
`

const CommunityGroups: FC = () => {
  return (
    <>
      <Paragraph>
        Community Groups meet on Friday nights and are a great way to connect
        with the CrossLife community. Community Groups are open to all and vary
        in location, topics, and cadence. Please contact <PlanningMailToLink />{' '}
        for more details on the community groups.
      </Paragraph>
      <StyledInfoBox variant="secondary">
        {listOfCommuntiyGroupInfo.map((communityGroup) => (
          <Stack direction="column" sx={{ paddingBottom: sectionSpacing }}>
            <Typography
              variant="h3"
              sx={{ margin: `${sectionSpacing} 0 20px` }}
            >
              {communityGroup.name}
            </Typography>
            <InfoLine alignItems="center" direction="row">
              <LabelIcon icon={<Info />}>
                {communityGroup.topicDescription}
              </LabelIcon>
            </InfoLine>
            <InfoLine alignItems="center" direction="row">
              <LabelIcon icon={<Event />}>{communityGroup.date}</LabelIcon>
            </InfoLine>
            <InfoLine alignItems="center" direction="row">
              <LabelIcon icon={<LocationOn />}>
                {communityGroup.location}
              </LabelIcon>
            </InfoLine>
          </Stack>
        ))}
      </StyledInfoBox>
    </>
  )
}

export default CommunityGroups
