import { InfoCardContentProps } from '../common/InfoCard'

/**
 * Welcome section
 */
export const WELCOME_TEXT_1 =
  "Welcome to CrossLife! We're so glad you're here, and we invite you to worship with us. Whether you're new to the area or new to Christianity, we look forward to meeting you."
export const WELCOME_TEXT_2 = 'Meet & Greets'
export const WELCOME_TEXT_3 =
  'We encourage you to attend one of our Meet & Greets held after service, where you can meet Pastor James, Pastor Matt, and other members of the church, as well as ask any questions you may have.'
export const WELCOME_TEXT_4 =
  'Upcoming Meet & Greet Dates: January 19 and April 13'

/**
 * The Gospel (The Good News) section
 */
export const GOSPEL_LIST: InfoCardContentProps[] = [
  {
    title: 'The Bible teaches that we are all sinners.',
    description:
      "As sinners, we inherently sin against God, which is anything that opposes God in motive, thought, word, or deed. Both the Bible and our own consciences testify that we are all guilty of sin, whether it be lust, deception, anger, immorality, drunkenness, theft, lying, or any other form of sin (Romans 3:23). Just as a criminal who breaks the law must be punished, our sins, which are the breaking of God's moral law, also deserve punishment. The penalty established by God for lawbreakers is death and eternal condemnation in hell (Romans 6:23).",
  },
  {
    title:
      'However, in His love, God sought to save us from the damnation that we deserved.',
    description:
      "He sent His Son, Jesus Christ, who is fully God, to come into the world as a man (John 1:1, 14). Although Jesus lived a perfect life, He willingly took our sins upon Himself and suffered the condemnation and death we deserve by dying on the cross (2 Corinthians 5:21). Amazingly, three days later, He physically resurrected from the dead in power and glory (Matthew 28:1-9). Jesus' resurrection demonstrates His power to fix man's greatest problem of sin that leads to eternal death and confirms that God accepted Christ's sacrifice on our behalf.",
  },
  {
    title:
      "The Bible tells us that if we have faith in Jesus' sacrifice on the cross and resurrection, then His saving work will be applied to us.",
    description:
      "God will grant us forgiveness because of what His Son suffered in payment of our sins and He will gift us eternal life because of the power of Jesus' resurrection. There is no religious ceremony or amount of good works that are required to receive this gift of salvation (Ephesians 2:8-9). It is only by faith that we repent from our sinful ways and declare Jesus to be the Lord of our lives and our only hope for salvation. As an outflow of true faith, we live our lives in love and service to the God who has so graciously saved us (Ephesians 2:10, Titus 2:11-14).",
  },
  {
    title:
      'In John 3:16 it says, "For God so loved the world that He gave His only Son, that whoever believes in Him should not perish but have eternal life."',
    description:
      'May you, by faith, receive the love of God expressed through the sacrifice and resurrection of His Son and enjoy the gift of eternal life in Him.',
  },
]
export const GOSPEL_INVITATION_TEXT =
  "Having experienced the joy of salvation ourselves, it is our passion to spread God's message so that others may share in His salvation with us. If you have any questions about the gospel, Christianity, or anything else, please come talk with us."

/**
 * Times and location section
 */
export const TIME_TITLE = 'TIME'
export const LOCATION_TITLE = 'LOCATION'

export const FULL_LOCATION_TEXT_1 = 'Voyagers Bible Church'
export const FULL_LOCATION_TEXT_2 = '1 Tarocco'
export const FULL_LOCATION_TEXT_3 = 'Irvine, CA 92618'

/**
 * Rides section
 */
type LocationAndTime = { location: string; time: string }

export const RIDES_DESCRIPTION =
  "If you're a student who would like a ride and aren't near UCI, please contact"
export const PICK_UP_TIMES_TITLE = 'PICK UP LOCATIONS AND TIMES'
export const PICK_UP_LOCATIONS_AND_TIMES_LIST: LocationAndTime[] = [
  { location: 'Entrance to Mesa Court Parking Lot 5', time: '3:15PM' },
  { location: 'Middle Earth Student Health Center', time: '3:20PM' },
  { location: 'Anteater Recreation Center Bus Stop', time: '3:25PM' },
]
export const RETURNING_RIDES_TITLE = 'RETURNING RIDES'
export const RETURNING_RIDES_AND_TIMES_LIST: LocationAndTime[] = [
  { location: 'Church Parking Lot', time: '6:15PM' },
  { location: 'Members Meeting Sundays', time: '5:50PM' },
]
export const RIDES_SIGN_UP_TITLE = 'RIDES SIGN UP FORM'

/**
 * FAQS section
 */

export const FAQ_LIST: InfoCardContentProps[] = [
  {
    title: 'Where should I park?',
    description:
      'If the parking lot is full, there is also street parking available along Tarocco.',
  },
  {
    title: 'Is there a dress code?',
    description:
      "No. You'll see a wide range of styles, with most people dressing in casual or business casual attire.",
  },
  {
    title: 'Should I introduce myself to anyone?',
    description:
      'Yes! Either before or after service, please stop by the Welcome Table in the foyer so we can meet you, help you navigate the buildings, or answer any questions you may have.',
  },
  {
    title: 'What happens during a service?',
    description:
      'Our usual service follows the following order of events:<ul><li>Welcome and Announcements</li><li>Praise (a mix of contemporary songs and traditional hymns)</li><li>Offering (as a guest, please do not feel obligated to give)</li><li>Scripture Reading</li><li>Sermon (expository preaching; sermons are recorded and available for download or viewing on YouTube <a href="/sermon">HERE</a>)</li><li>Closing Praise</li><li>Benediction</li></ul>',
  },
  {
    title: 'How long is service?',
    description:
      'Sunday service is from 4PM-5:30PM, so about one and a half hours.',
  },
  {
    title: 'What happens after a service?',
    description:
      'Join us in the MPR building across the patio for dinner (the 1st and 3rd Sundays of each month) or snacks. ',
  },
  {
    title: 'Do you have mid-week gatherings?',
    description:
      'Yes! For collegians, please visit <a href="/ministry/college">HERE</a>. For anyone else, please visit <a href="/ministry/community-groups">HERE</a>.',
  },
  {
    title: "Is there a children's ministry?",
    description:
      'Yes! Learn more about our children\'s ministry <a href="/ministry/children">HERE</a>.',
  },
  {
    title: 'How do I become a member?',
    description:
      'Learn more about our membership process <a href="/class/church-membership">HERE</a>.',
  },
]

/**
 * Leadership section
 */
export const LEADERSHIP_CONTACT = 'To contact the leadership, please email '
export const BIOGRAPHY_TITLE = 'BIOGRAPHY'
export const EDUCATION_TITLE = 'EDUCATION'
export const FAVORITE_VERSE_TITLE = 'FAVORITE VERSE'
export const HOBBIES_TITLE = 'HOBBIES'

/**
 * Doctrinal Statement section
 */

export const DOCTRINAL_STATEMENT_LIST: InfoCardContentProps[] = [
  {
    title: 'God',
    description:
      'We believe in one God (1 Corinthians 8:4; Ephesians 4:6) who eternally exists in three persons -- the Father, the Son, and the Holy Spirit (Matthew 28:19; John 1:1; Acts 5:4-5; Romans 9:5; Titus 2:13; 1 John 5:20).',
  },
  {
    title: 'Christ',
    description:
      'We believe in the deity and humanity of the Lord Jesus Christ (John 1:1; Philippians 2:7-8; Hebrews 2:17), His virgin birth (Matthew 1:18-25), His sinless life (Hebrews 9:14), His miracles (Matthew 4:23-24), His vicarious and atoning death (Romans 5:9; 2 Corinthians 5:21), His bodily resurrection (1 Corinthians 15:20), His ascension to the right hand of the Father (Psalm 110:1), and His personal return in power and glory (Matthew 24:30).',
  },
  {
    title: 'The Holy Spirit',
    description:
      'We believe in the present ministry of the Holy Spirit through whom believers have been baptized (1 Corinthians 12:13) and sealed for the day of future glory (Ephesians 1:13; 2 Corinthians 5:5). He dwells within every believer (1 Corinthians 3:16; 6:19) and enables them to live a godly life (Romans 8:1-11).',
  },
  {
    title: 'The Bible',
    description:
      'We believe in the inspiration, the inerrancy, and the infallibility of the Bible (2 Timothy 3:16; 2 Peter 1:20-21).',
  },
  {
    title: 'Sin and Sin Nature',
    description:
      "We believe in original sin and the total depravity of man. Through Adam's transgression, sin has entered into the world so that man is born with a sin nature (Psalm 51:5; Romans 5:12-21). As a result he is completely helpless to save himself from sin and hell (Romans 3:10-11; 6:23).",
  },
  {
    title: 'Salvation',
    description:
      "We believe that salvation is based on Jesus' atoning death and life-giving resurrection (Romans 3:24-25; 4:25), offered to men solely by the grace of God, and received through faith apart from works (Ephesians 2:8-9).",
  },
  {
    title: 'The End Times',
    description:
      'We believe in the rapture of the church (1 Thessalonians 4:16-17), the second coming of Jesus Christ (Acts 1:11), the establishment of the millennial kingdom (Revelation 20:4), the bodily resurrection of both the saved and the lost -- the saved to eternal life with Christ and the lost to eternal punishment in hell (Revelation 20:4-6; 11-15) -- and the coming of the new heavens and the new earth (Revelation 21:1).',
  },
  {
    title: 'Marriage',
    description:
      "We believe that God instituted marriage to depict Christ's love for His church (Ephesians 5:31-32). It is defined by Scripture solely as the union between one man and one woman in a covenant commitment for life (Genesis 2:18-22; Matthew 19:6). We believe that the husband and wife are essentially equal as image-bearers of God, but with distinct and complementary roles (Genesis 1:27-28, 1 Corinthians 11:3; Ephesians 5:22-30).",
  },
  {
    title: 'Anthropology',
    description:
      "We believe that God created humankind in His image as male and female (Genesis 1:26-27; 2:21-23). Therefore, manhood and womanhood is biologically determined so that a man has XY chromosomes and a woman has XX chromosomes. The humanity of both the man and the woman reflect the oneness of God. The distinction between their gender reflects the plurality within the Godhead.  Therefore, to deny a person's humanhood and sexuality is to assault the nature of God whose image we bear.",
  },
]

/**
 * Membership covenant section
 */
export const PREAMBLE_TITLE = 'Preamble'
export const PREAMBLE_DESCRIPTION_1 =
  'Members of CrossLife Bible Church worship together under a covenant: a statement on how we agree to live as a church. The church covenant consists of promises to one other, a summary of expectations, an ethical statement, and biblical standards to obey. Our covenant clarifies all of these issues in order to pursue and have mutual accountability. These biblical truths form our worldview and hold the standard by which we live. Our acceptance of this covenant continues the practice of believers throughout the centuries who have pledged to God and one another to live out the gospel in community.'
export const PREAMBLE_DESCRIPTION_2 =
  'We use our covenant in two key ways today. We require all new members to agree with and commit to the covenant before joining the church. We also regularly reaffirm our commitment to the covenant at members’ meetings. By committing to it and being reminded of the commitment to the covenant in our life together, we strive to protect ourselves from individual and corporate sins and to spur one another on to live in light of the greatest covenant, one initiated by love, sealed by sacrifice, and kept for eternity by our Lord and Savior, Jesus Christ.'
export const COVENANT_TITLE = 'Covenant'
export const COVENANT_DESCRIPTION_1 =
  'Having been brought by divine grace to repent and believe in Jesus Christ, having committed myself to the Lord and Savior, and having been baptized upon my profession of faith, in the name of the Father, Son, and Holy Spirit, I do now, solemnly and joyfully covenant with the members of CrossLife Bible Church to do the following:'
export const COVENANT_COMMITMENT_LIST = [
  'I will seek, by Divine aid, to live carefully in the world, denying ungodliness and worldliness while striving to live a faithful and holy life.',
  'I will not forsake the regular assembling of God’s people.',
  'I will be faithful to remember and proclaim the Lord’s death in anticipation of His second return by partaking in communion.',
  'I will work and pray for the unity of the Spirit in the bond of peace.',
  'I will submit to the loving leadership of this church, cultivating a spirit of humility and teachability.',
  'I will exercise an affectionate care and watchfulness over other members, and faithfully exhort and entreat them as occasion may require.',
  'I will carry out biblical church discipline and submit to the church’s discipline of others or myself, extending forgiveness and pursuing reconciliation as needed.',
  'I will rejoice in the happiness of others and endeavor to bear other’s burdens and sorrows with tenderness and sympathy.',
  'I will contribute cheerfully and regularly to the kingdom, remembering that giving to the Lord supports the ministry and expenses of the church, the relief of the helpless, and the spread of the Gospel to all nations.',
  'I will work for the continuance of God-glorifying ministry in this church, as I help to sustain its worship of the living God.',
  'I will strive to share the gospel with unbelievers in obedience to the great commission.',
  'I will, when I move from this place, as soon as possible, unite with some other church where I can carry out the spirit of this covenant and the principles of God’s Word.',
]
export const COVENANT_DESCRIPTION_2 =
  'May the grace of the Lord Jesus Christ, and the love of God, and the fellowship of the Holy Spirit be with us all. Amen.'
