import { Alert, Box, Typography, Paper } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import { PlanningMailToLink } from '../common/MailToLink'
import {
  RIDES_DESCRIPTION,
  PICK_UP_TIMES_TITLE,
  PICK_UP_LOCATIONS_AND_TIMES_LIST,
  RETURNING_RIDES_TITLE,
  RETURNING_RIDES_AND_TIMES_LIST,
  RIDES_SIGN_UP_TITLE,
} from './strings'

const StyledImage = styled.img`
  width: 100%;
  margin-top: 25px;
`

const RidesSection: FC = () => {
  return (
    <>
      <Alert severity="info" sx={{ marginBottom: '25px' }}>
        {RIDES_DESCRIPTION} <PlanningMailToLink />.
      </Alert>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" align="center">
          {PICK_UP_TIMES_TITLE}
        </Typography>
        {PICK_UP_LOCATIONS_AND_TIMES_LIST.map((ride) => {
          return (
            <Typography align="center" gutterBottom>
              {ride.location} | {ride.time}
            </Typography>
          )
        })}
        <Typography variant="h6" align="center" sx={{ marginTop: '25px' }}>
          {RETURNING_RIDES_TITLE}
        </Typography>
        {RETURNING_RIDES_AND_TIMES_LIST.map((ride) => {
          return (
            <Typography align="center" gutterBottom>
              {ride.location} | {ride.time}
            </Typography>
          )
        })}
        <StyledImage
          src="https://assets.crosslifebiblechurch.org/college-rides-map.png"
          alt="College Rides Map"
        />
        <Typography variant="h6" align="center" sx={{ marginTop: '25px' }}>
          {RIDES_SIGN_UP_TITLE}
        </Typography>
        <Paper
          elevation={5}
          sx={{
            marginTop: '25px',
            textAlign: 'center',
            border: '1px solid #B0B0B0',
            padding: '15px',
          }}
        >
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfm8xygFc-gk7qfzm_cJlR5L80KDBWqfUa9k_j68D3d7AVYTg/viewform?embedded=true"
            width="100%"
            height="600"
            title="Rides Sign Up Form"
            style={{ maxWidth: '720px', margin: '0 auto', border: 'none' }}
          >
            Loading…
          </iframe>
        </Paper>
      </Box>
    </>
  )
}

export default RidesSection
